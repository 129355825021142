import React          from 'react';
import { graphql }    from 'gatsby';
import { GiMetalBar } from 'react-icons/gi';
import styled         from '@emotion/styled';
import Img            from 'gatsby-image';

import Wrapper      from '@interness/web-core/src/components/structure/Wrapper/Wrapper';
import Spacer       from '@interness/web-core/src/components/structure/Spacer/Spacer';
import Heading      from '@interness/web-core/src/components/text/Heading/Heading';
import CallToAction from '@interness/theme-sonora/src/components/CallToAction/CallToAction';
import Lightbox     from '@interness/web-core/src/components/media/Lightbox/Lightbox';

const SDisplay = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`;

const SDisplayItem = styled.div`
  width: ${props => props.width};
  margin-bottom: 50px;
  position: relative;
  @media (max-width: 640px) {
    width: 100%;
  }

  > div {
    margin-bottom: 20px;
    position: relative;
    border: 5px solid ${props => props.theme.colorMode === 'dark' ? 'rgba(255,255,255,0.8)' : 'rgba(0,0,0,0.7)'} !important;
  }

  h4 {
    background-color: ${props => props.theme.colorMode === 'dark' ? 'rgba(255,255,255,0.8)' : 'rgba(0,0,0,0.7)'} !important;
    color: ${props => props.theme.colorMode === 'dark' ? '#333' : '#fff'} !important;
    text-transform: uppercase;
    padding: 10px 0;
    margin: auto;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    text-align: center;
  }

  h5 {
    font-size: 1.2rem;
    font-weight: 300;
    text-align: left;
    margin-top: 10px;
  }

  p {
    text-align: justify;
    font-size: 0.9rem;
  }

  a {
    position: absolute;
    bottom: -42px;
  }
`;


const EinblickePage = (props) => {
  return (
    <Wrapper>
      <Spacer/>
      <Heading icon={<GiMetalBar/>} subtitle="Das erwartet Sie in unserem Trauringstudio">Einblicke</Heading>
      <Spacer/>
      <div>
        <p>Wir freuen uns, Sie in unserem exklusiven Trauringstudio am Hauptmarkt 8 begrüßen zu dürfen.</p>
        <p>Wir begleiten Sie auf einer emotionalen Reise, um jene Ringe zu entdecken, die nicht nur Ihren Finger, sondern auch Ihr Herz für immer schmücken werden.</p>
        <p>Bei uns dreht sich alles um die Symbole Ihrer Liebe: Trauringe, die die Ewigkeit versprechen, Eheringe, die Ihre Seelen vereinen, Verlobungsringe, die den Beginn eines neuen Kapitels markieren, und Antragsringe, die die Frage aller Fragen einleiten. Unser Herz schlägt dafür, Ihnen ein Erlebnis zu bereiten, das so unvergesslich und einzigartig ist wie Ihre Liebe selbst.</p>
        <p>Tauchen Sie ein in unsere Welt aus atemberaubenden Kollektionen, die mit Liebe zum Detail, Leidenschaft für Design und Hingabe an Qualität handverlesen wurden. Ob die Suche Sie zu einem klassischen Ehering führt, der Zeit und Trends überdauert, oder zu einem funkelnden Antragsring. Bei uns finden Sie den Schlüssel zu Ihrem Glück. Unser Versprechen an Sie ist, jeden Wunsch, jede Idee und jedes Traumbild mit Empathie und Fachwissen in die Realität umzusetzen.</p>
        <p>Nachhaltigkeit liegt uns ebenso am Herzen. Wir sind stolz darauf, Ihnen zu zeigen, dass Liebe zur Umwelt und außergewöhnlicher Schmuck Hand in Hand gehen können. Unsere Ringe sind ein Zeugnis unserer Verantwortung, gefertigt mit Sorgfalt und Respekt, aus Materialien, die nicht nur schön, sondern auch nachhaltig sind.</p>
        <p>Besuchen Sie unsere Trauringstudio Grimm, am Hauptmarkt 8 in Siegen Weidenau, um Ihre Traumringe zu entdecken und Ihre Liebesgeschichte mit uns zu beginnen. Wir möchten Ihre Wünsche und Erwartungen übertreffen und Ihnen die Ringe zu bieten, die Ihre Liebe und Verbindung perfekt symbolisieren. Wir freuen uns auf Ihren Besuch!</p>

      </div>
      <Spacer/>
      <SDisplay>
        {props.data.team.nodes.map(item => (
          <SDisplayItem width={'45%'} key={item.name}>
            <div>
              <Img fluid={item.portrait.localFile.childImageSharp.fluid} alt={item.name}/>
              <h4>{item.name}</h4>
            </div>
          </SDisplayItem>
        ))}
      </SDisplay>
      <Spacer/>
      <div>
        <Lightbox thumbnailWidth="220px" images={props.data.gallery.images} thumbnails={props.data.gallery.thumbnails} fromApi/>
      </div>
      <Spacer />      <CallToAction imageFileTitle="theme-sonora-call-to-action-background-trauringe"/>
      <Spacer/>
    </Wrapper>
  )
};

export default EinblickePage;

export const query = graphql`
    query {
        gallery: directusMediaCollection(name: {eq: "einblicke"}) {
            ...LightboxGalleryGrid
        }
        team: allDirectusTeamMembers(filter: {id: {ne: "dummy"}}) {
            nodes {
                name
                portrait {
                    localFile {
                        name
                        childImageSharp {
                            fluid(maxWidth: 530, quality: 95) {
                                ...GatsbyImageSharpFluid
                            }
                        }
                    }
                }
                translations {
                    language
                    description
                }
            }
        }
    }
`;
